import EN from "@/utils/translations/en.json"
import NL from "@/utils/translations/nl.json"
import ES from "@/utils/translations/es.json"
import Head from "next/head"
import { useSession } from "next-auth/react"
import styled from "styled-components"
import Footer from "@/components/common/Layout/footer/Footer"
import fetchHead from "@/utils/fetchHead"
import axios from "axios"
import Home from "./home/index"
import growthbook from "@/lib/growthbook/growthbook"
import { useRouter } from "next-translate-routes"
import uuid from "react-uuid"
import { useEffect } from "react"

function Homepage({ title, meta }) {
  const { data: session } = useSession()
  const router = useRouter()

  // useEffect(() => {
  //   growthbook.setAttributes({
  //     id: session?.id || uuid(),
  //     loggedIn: session?.user ? true : false,
  //     country: navigator.language || navigator.userLanguage,
  //     browser: navigator.userAgent,
  //     url: router.pathname,
  //   });
  // }, [session, router.pathname]);

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={meta} />
      </Head>
      <Wrapper>
        <Home session={session} />
      </Wrapper>
      <Footer />
    </>
  )
}

export default Homepage

const Wrapper = styled.div`
  width: 100%;
  min-height: 90vh;

  .headerBar {
    box-shadow: none;
    position: relative;
    z-index: 99999;
  }
`

export async function getStaticProps({ locale }) {
  try {
    // Define translations more directly
    const translations = {
      EN: EN,
      NL: NL,
      ES: ES,
    }

    const title = await fetchHead(
      "head_title_homepage",
      translations,
      locale ? locale : "en"
    )
    const meta = await fetchHead(
      "head_meta_homepage",
      translations,
      locale ? locale : "en"
    )

    return {
      props: {
        title,
        meta,
      },
      // Revalidate every 24 hours
      revalidate: 60 * 60 * 24,
    }
  } catch (error) {
    console.log(error)
    return {
      notFound: true,
    }
  }
}
